<template>
  <div>
    <!-- SELECT FORMAT -->
    <div class="text-center" v-if="!form.format">
      <p>Selecione o tipo de criativo a ser adicionado</p>
      <div class="mt-4 d-flex flex-column flex-lg-row align-items-center justify-content-center mb-4">
        <Button
          class="btn btn-primary mb-3 mb-lg-0 me-3"
          @click="selectFormat('DISPLAY')"
        >
          Display
        </Button>
        <Button
          class="btn btn-primary mb-3 mb-lg-0"
          @click="selectFormat('NATIVE_DISPLAY')"
        >
          Native
        </Button>
      </div>
    </div> <!-- END SELECT FORMAT -->
    <!-- FORM -->
    <div v-else>
      <div
        :class="{
          'd-flex flex-column flex-lg-row justify-content-lg-center': hasPreview
        }"
      >
        <form
          :class="{
            'col-lg-6': hasPreview
          }"
          @submit.prevent="submit" class="text-start default-form"
        >
          <div class="mb-2" v-if="!this.format">
            <a href="#" @click.prevent="restart(false)"><i class="fa fa-angle-left me-2"></i>Voltar</a>
          </div>
          <!-- NAME -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Nome</label>
            <input type="text" class="form-control" placeholder="Nome" v-model.trim="$v.form.name.$model" />
            <p class="font-size-xSmall text-end mt-1 mb-0"
              :class="{
                'text-red': $v.form.name.$model.length > 128
              }"
            >{{ `${ $v.form.name.$model.length }/128` }}</p>
            <p
              class="font-size-xSmall fw-bold mt-2 error-message-card"
              v-if="$v.form.name.$model.length > 128"
            >
              Nome não pode conter mais do que 128 caracteres
            </p>
          </div> <!-- END NAME -->
          <!-- URL -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold">URL de destino</label>
            <div class="input-group mb-3">
              <span class="input-group-text">https://</span>
              <input type="text" class="form-control" placeholder="url.com.br" v-model.trim="$v.form.url.$model" />
            </div>
          </div> <!-- END URL -->
          <!-- FORMAT -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Formato</label>
            <select class="form-control" v-model="$v.form.format.$model" disabled>
              <option value=""></option>
              <option :value="format.value" v-for="(format, index) in formatOptions" :key="index">{{ format.label }}</option>
            </select>
          </div> <!-- END FORMAT -->
          <!-- FILE -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Criativo</label><br/>
            <input type="file" @change="changeFile" accept="image/*" />
            <p class="font-size-xSmall" v-if="form.format === 'NATIVE_DISPLAY'">Apenas imagens PNG ou JPG com no máximo 800kb</p>
            <p class="font-size-xSmall" v-else-if="form.format === 'DISPLAY'">Apenas imagens PNG, JPG ou GIF com no máximo 800kb</p>
            <p class="font-size-xSmall">Dimensões permitidas: {{ allowedLayouts }}</p>
            <p class="font-size-xSmall" v-if="fileNotAllowed" style="color: #ff0000;"><strong>Atenção:</strong> Arquivo selecionado de dimensões não permitidas!</p>
            <p class="font-size-xSmall" v-if="fileDifferentLayout" style="color: #ff0000;"><strong>Atenção:</strong> Arquivo selecionado não pode possuir dimensões diferentes do arquivo atual!</p>
          </div> <!-- END FILE -->
          <!-- LAYOUT -->
          <div class="form-group mb-3">
            <label class="form-label fw-bold">Tamanho</label>
            <select class="form-control" v-model="$v.form.layout.$model" disabled>
              <option value=""></option>
              <option :value="layout.value" v-for="(layout, index) in layoutOptions" :key="index">{{ layout.label }}</option>
            </select>
          </div> <!-- END LAYOUT -->
          <template v-if="form.format === 'NATIVE_DISPLAY'">
            <!-- LOGO -->
            <div class="form-group mb-3">
              <label class="form-label fw-bold">Logo</label><br/>
              <input type="file" @change="changeLogoFile" accept="image/*" />
              <p class="font-size-xSmall">Apenas imagens PNG ou JPG com no máximo 800kb</p>
              <p class="font-size-xSmall">Tamanhos permitidos: 200x200</p>
              <p class="font-size-xSmall" v-if="logoFileNotAllowed" style="color: #ff0000;"><strong>Atenção:</strong> Arquivo selecionado de tamanho não permitido!</p>
            </div> <!-- END LOGO -->
            <!-- ADVERTISER DISPLAY NAME -->
            <div class="form-group">
              <label class="form-label fw-bold">Nome de exibição do anunciante</label>
              <input type="text" class="form-control" placeholder="Título" v-model.trim="$v.form.advertiser_display_name.$model" />
              <p class="font-size-xSmall text-end mt-1 mb-0"
                :class="{
                  'text-red': form.advertiser_display_name.length > 25
                }"
              >{{ `${ form.advertiser_display_name.length }/25` }}</p>
              <p
                class="font-size-xSmall fw-bold mt-2 error-message-card"
                v-if="form.advertiser_display_name.length > 25"
              >
                Nome de exibição do anunciante não pode conter mais do que 25 caracteres
              </p>
            </div> <!-- END ADVERTISER DISPLAY NAME -->
            <!-- HEADLINE -->
            <div class="form-group">
              <label class="form-label fw-bold">Título</label>
              <input type="text" class="form-control" placeholder="Título" v-model.trim="$v.form.headline.$model" />
              <p class="font-size-xSmall text-end mt-1 mb-0"
                :class="{
                  'text-red': form.headline.length > 25
                }"
              >{{ `${ form.headline.length }/25` }}</p>
              <p
                class="font-size-xSmall fw-bold mt-2 error-message-card"
                v-if="form.headline.length > 25"
              >
                Título não pode conter mais do que 25 caracteres
              </p>
            </div> <!-- END HEADLINE -->
            <!-- BODY TEXT -->
            <div class="form-group">
              <label class="form-label fw-bold">Corpo do texto</label>
              <input type="text" class="form-control" placeholder="Corpo" v-model.trim="$v.form.body_text.$model" />
              <p class="font-size-xSmall text-end mt-1 mb-0"
                :class="{
                  'text-red': form.body_text.length > 90
                }"
              >{{ `${ form.body_text.length }/90` }}</p>
              <p
                class="font-size-xSmall fw-bold mt-2 error-message-card"
                v-if="form.body_text.length > 90"
              >
                Corpo do texto não pode conter mais do que 90 caracteres
              </p>
            </div> <!-- END BODY TEXT -->
            <!-- CTA -->
            <div class="form-group">
              <label class="form-label fw-bold">
                Call to action
                <span
                  id="cta-tooltip"
                >
                  <i class="fa fa-info-circle font-size-regular"></i>
                </span>
              </label>
              <b-tooltip target="cta-tooltip">
                <strong>Exemplos</strong><br/>
                Clique aqui<br/>
                Saiba Mais<br/>
                Fale Conosco
              </b-tooltip>
              <input type="text" class="form-control" placeholder="Saiba mais" v-model.trim="$v.form.call_to_action.$model" />
              <p class="font-size-xSmall text-end mt-1 mb-0"
                :class="{
                  'text-red': form.call_to_action.length > 15
                }"
              >{{ `${ form.call_to_action.length }/15` }}</p>
              <p
                class="font-size-xSmall fw-bold mt-2 error-message-card"
                v-if="form.call_to_action.length > 15"
              >
                Call to action não pode conter mais do que 15 caracteres
              </p>
            </div> <!-- END CTA  -->
            <!-- CAPTION URL -->
            <div class="form-group">
              <label class="form-label fw-bold">
                URL de exibição
                <span
                  id="cta-caption-url"
                >
                  <i class="fa fa-info-circle font-size-regular"></i>
                </span>
              </label>
              <b-tooltip target="cta-caption-url">
                Uma versão curta e amigável da URL da página de destino a ser exibida no criativo. Essa URL dá às pessoas uma ideia de onde elas chegarão depois de clicar no criativo. Recomendamos o uso da URL raiz do domínio ou subdomínio.
              </b-tooltip>
              <input type="text" class="form-control" placeholder="Saiba mais" v-model.trim="$v.form.caption_url.$model" />
              <p class="font-size-xSmall text-end mt-1 mb-0"
                :class="{
                  'text-red': form.caption_url.length > 30
                }"
              >{{ `${ form.caption_url.length }/30` }}</p>
              <p
                class="font-size-xSmall fw-bold mt-2 error-message-card"
                v-if="form.caption_url.length > 30"
              >
                URL de exibição não pode conter mais do que 30 caracteres
              </p>
            </div> <!-- END CAPTION URL  -->
          </template>
          <!-- AVAILABILITY -->
          <div class="form-group mb-3" v-if="1 == 2">
            <label class="form-label fw-bold">Disponibilidade</label><br/>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="av-desktop" v-model="form.is_desktop">
              <label class="form-check-label" for="av-desktop">Desktop</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="av-mobile" v-model="form.is_mobile">
              <label class="form-check-label" for="av-mobile">Mobile</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="av-app" v-model="form.is_app">
              <label class="form-check-label" for="av-app">Aplicativos</label>
            </div>
          </div> <!-- END AVAILABILITY -->
        </form>
        <!-- PREVIEW -->
        <div class="col-lg-6 text-start px-5  mt-5" v-if="hasPreview">
          <h3 class="highlighted-color1 mb-4">
            <span>Prévia</span>
          </h3>
          <CreativePreviewWrapper
            :advertiser="advertiser"
            :form="form"
            :creative="creative"
            :isForm="true"
          />
        </div> <!-- END PREVIEW -->
      </div>
      <!-- ACTIONS -->
      <div class="text-center mt-3">
        <!-- MESSAGES -->
        <div
          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
          v-if="loader"
        >
          <p
            class="mb-0"
          >
            Essa ação pode levar alguns minutos.
          </p>
        </div> <!-- END MESSAGES -->
        <Button
          type="button"
          class="btn btn-primary me-3"
          @click="submit"
          :loading="loader"
          :disabled="$v.$invalid"
        >
          {{ creative ? 'Editar' : 'Criar' }}
        </Button>
      </div> <!-- END ACTIONS -->
    </div> <!-- END FORM -->
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CreativesService from '@/modules/creatives/services/creatives-service'
import { required, maxLength } from 'vuelidate/lib/validators'

import Button from '@/components/common/Button/Button'
import CreativePreviewWrapper from '@/components/creatives/CreativePreview/CreativePreviewWrapper'

export default {
  name: 'UpsertCreativeForm',
  props: ['advertiserId', 'creative', 'format'],
  components: {
    Button,
    CreativePreviewWrapper
  },
  data () {
    return {
      advertiser: '',
      loader: false,
      form: {
        advertiser_id: '',
        name: '',
        format: '',
        layout: '',
        url: '',
        is_desktop: true,
        is_mobile: true,
        is_app: true,
        file: null,
        logo: null,
        headline: '',
        body_text: '',
        call_to_action: '',
        advertiser_display_name: '',
        caption_url: ''

      },
      fileNotAllowed: false,
      fileDifferentLayout: false,
      formatOptions: [
        {
          label: 'Display',
          value: 'DISPLAY'
        },
        {
          label: 'Native Display',
          value: 'NATIVE_DISPLAY'
        }
      ],
      layoutOptions: [
        {
          label: '300x250',
          value: '300x250',
          format: 'DISPLAY',
          width: 300,
          height: 250
        },
        {
          label: '300x600',
          value: '300x600',
          format: 'DISPLAY',
          width: 300,
          height: 600
        },
        {
          label: '320x450',
          value: '320x450',
          format: 'DISPLAY',
          width: 320,
          height: 450
        },
        {
          label: '320x480',
          value: '320x480',
          format: 'DISPLAY',
          width: 320,
          height: 480
        },
        {
          label: '336x280',
          value: '336x280',
          format: 'DISPLAY',
          width: 336,
          height: 280
        },
        {
          label: '728x90',
          value: '728x90',
          format: 'DISPLAY',
          width: 728,
          height: 90
        },
        {
          label: '627x627',
          value: '627x627',
          format: 'NATIVE_DISPLAY',
          width: 627,
          height: 627
        },
        {
          label: '1200x627',
          value: '1200x627',
          format: 'NATIVE_DISPLAY',
          width: 1200,
          height: 627
        }
      ],
      logoFileNotAllowed: false
    }
  },
  async created () {
    this.form.advertiser_id = this.advertiserId
    if (this.creative) {
      this.form.name = this.creative.name
      this.form.format = this.creative.format
      this.form.layout = this.creative.layout
      this.form.url = this.creative.url.replace('https://', '').replace('http://', '')
      this.form.is_app = this.creative.is_app
      this.form.is_desktop = this.creative.is_desktop
      this.form.is_mobile = this.creative.is_mobile
      this.form.headline = this.creative.headline
      this.form.body_text = this.creative.body_text
      this.form.call_to_action = this.creative.call_to_action
      this.form.advertiser_display_name = this.creative.advertiser_display_name ? this.creative.advertiser_display_name : ''
      this.form.caption_url = this.creative.caption_url ? this.creative.caption_url : ''
    }
    if (this.format) this.form.format = this.format
    this.getAdvertiser()
  },
  validations () {
    const form = {
      form: {
        name: { required, maxLength: maxLength(128) },
        format: { required },
        layout: { required },
        url: { required }
      }
    }

    if (this.form.format === 'NATIVE_DISPLAY') {
      // form.form.logo = { required }
      form.form.advertiser_display_name = { required, maxLength: maxLength(25) }
      form.form.headline = { required, maxLength: maxLength(25) }
      form.form.body_text = { required, maxLength: maxLength(90) }
      form.form.call_to_action = { required, maxLength: maxLength(15) }
      form.form.caption_url = { required, maxLength: maxLength(30) }
    }

    return form
  },
  computed: {
    /**
     * Allowed layouts
     */
    allowedLayouts () {
      const filtered = this.layoutOptions.filter(item => {
        return item.format === this.form.format
      })
      const sizes = filtered.map(item => {
        return item.label
      })
      return sizes.join(', ')
    },
    /**
     * Check if form has preview
     */
    hasPreview () {
      return this.form.format === 'NATIVE_DISPLAY'
    }
  },
  methods: {
    /**
     * Handle change file
     */
    changeFile (e) {
      const file = e.target.files[0]
      this.form.name = file.name.split('.').slice(0, -1).join('.')
      // Validate if the file is in allowed layout
      const image = new Image()
      image.src = URL.createObjectURL(file)
      image.onload = () => {
        // If creative exists, check if image is different layout
        this.fileDifferentLayout = false
        if (this.creative) {
          const dimensions = this.form.layout.split('x')
          if (image.width !== parseInt(dimensions[0]) || image.height !== parseInt(dimensions[1])) {
            this.fileDifferentLayout = true
            return
          }
        }

        let allowed = false
        const filtered = this.layoutOptions.filter(item => {
          return item.format === this.form.format
        })
        filtered.forEach(item => {
          if (image.width === item.width && image.height === item.height) {
            allowed = true
            this.form.layout = item.value
            this.form.format = item.format
          }
        })

        if (allowed) {
          this.form.file = file
          this.fileNotAllowed = false
        } else {
          this.fileNotAllowed = true
          this.form.file = null
        }
      }
    },
    /**
     * Handle change logo file
     */
    changeLogoFile (e) {
      const file = e.target.files[0]

      // Validate if the file is in allowed layout
      const image = new Image()
      image.src = URL.createObjectURL(file)
      image.onload = () => {
        if (image.width === 200 && image.height === 200) {
          this.logoFileNotAllowed = false
          this.form.logo = file
        } else {
          this.logoFileNotAllowed = true
          this.form.logo = null
        }
      }
    },
    /**
     * Get advertiser
     */
    async getAdvertiser () {
      try {
        const advertiser = await AdvertisersService.getAdvertiser(this.advertiserId)
        this.advertiser = advertiser
        if (!this.creative) this.form.advertiser_display_name = advertiser.name
      } catch (error) {

      }
    },
    /**
     * Restart form
     */
    restart (partialReset = false) {
      this.form.name = ''
      this.form.layout = ''
      this.form.url = ''
      this.form.is_desktop = false
      this.form.is_mobile = false
      this.form.is_app = false
      this.form.file = null
      this.form.logo = null
      this.form.headline = ''
      this.form.body_text = ''
      this.form.call_to_action = ''
      this.form.advertiser_display_name = ''
      this.form.caption_url = ''
      if (!partialReset) this.selectFormat('')
    },
    /**
     * Select creative format
     */
    selectFormat (format) {
      this.form.format = format
      this.$emit('format-selected', format)
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        let creative = null
        const form = Object.assign({}, this.form)
        form.url = `https://${form.url.replace('https://', '').replace('http://', '')}`
        form.caption_url = form.caption_url.replace('https://', '').replace('http://', '')

        // If is display, remove display native info
        if (form.format === 'DISPLAY') {
          form.logo = null
          form.headline = ''
          form.body_text = ''
          form.call_to_action = ''
          form.advertiser_display_name = ''
          form.caption_url = ''
        }

        if (this.creative) creative = await CreativesService.updateCreative(this.creative.id, form)
        else creative = await CreativesService.createCreative(form)

        if (this.creative) this.$emit('creative-updated', creative)
        else {
          this.$emit('creative-created', creative)
          if (this.format) this.restart(true)
        }
      } catch (error) {
        this.$emit('upsert-creative-error', error)
      }
      this.loader = false
    }
  }
}
</script>
